<template>
  <div>
    <div class="card card-white">
      <div class="card-body pt-0">
        <div class="table-responsive">
          <div v-if="header" class="table-header px-0">
            <span class="table-title font-bold block-text">{{ title }}</span>
            <slot name="buttons"></slot>
            <div class="actions">
              <a
                v-for="(button, index) in customButtons"
                href="javascript:undefined"
                class="waves-effect btn-flat nopadding"
                v-show="button.hide ? !button.hide : true"
                @click="button.onclick"
                :key="index"
              >
                <i class="material-icons">{{ button.icon }}</i>
              </a>
              <a
                href="javascript:undefined"
                class="waves-effect btn-flat nopadding"
                v-if="this.printable"
                @click="print"
              >
                <i class="material-icons">print</i>
              </a>
              <a
                href="javascript:undefined"
                class="waves-effect btn-flat nopadding"
                v-if="this.exportable"
                @click="exportExcel"
              >
                <i class="material-icons">description</i>
              </a>
              <!-- <a href="javascript:undefined" class="waves-effect btn-flat nopadding" v-if="this.exportable" @click="exportCsv">
                                <i class="material-icons">file_copy</i>
                            </a> -->
              <a
                href="javascript:undefined"
                class="waves-effect btn-flat nopadding"
                v-if="this.searchable"
                @click="search"
              >
                <i class="material-icons">search</i>
              </a>
              <a
                data-bs-toggle="modal"
                data-bs-target="#settingTable"
                href="javascript:undefined"
                class="waves-effect btn-flat nopadding pr-0"
              >
                <!-- <i class="material-icons icon-settings-table mr-1 font-18">settings_applications</i>Cài đặt bảng  -->
                <i class="material-icons">visibility</i>
              </a>
            </div>
          </div>
          <div v-if="this.searching">
            <div id="search-input-container">
              <input
                type="search"
                id="search-input"
                class="form-control"
                :placeholder="lang['search_data']"
                :value="searchInput"
                @input="
                  (e) => {
                    this.searchInput = e.target.value;
                  }
                "
              />
            </div>
          </div>
          <table
            ref="table"
            class="display nowrap table table-hover table-striped"
            cellspacing="0"
            width="100%"
          >
            <thead>
              <tr class="font-bold">
                <th v-if="stt">#</th>
                <slot name="thead-tr-o"></slot>
                <th
                  v-show="
                    (column.admin && user.role == 'admin') || (!column.admin && column.is_show)
                  "
                  v-for="(column, index) in activeColumns"
                  :key="index"
                  @click="sort(index)"
                  :class="
                    (sortable ? 'sorting ' : '') +
                    (sortColumn === index
                      ? sortType === 'desc'
                        ? 'sorting-desc'
                        : 'sorting-asc'
                      : '') +
                    (column.numeric ? ' numeric' : '')
                  "
                  :style="{ width: column.width ? column.width : 'auto' }"
                >
                  {{ column.label }}
                </th>
                <slot name="thead-tr"></slot>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in paginated"
                :class="[{ 'cl-red': row.color }, { clickable: clickable }]"
                :key="index"
                @click="click(row)"
              >
                <td v-if="stt" style="width: 2%; vertical-align: middle">
                  {{ index + 1 + (currentPage - 1) * currentPerPage }}
                </td>
                <slot name="tbody-tr-o" :row="row" tyle="vertical-align: middle;"></slot>
                <td
                  v-show="
                    (column.admin && user.role == 'admin') || (!column.admin && column.is_show)
                  "
                  v-for="(column, index_col) in activeColumns"
                  :class="[{ numeric: column.numeric }, { 'text-center': column.center }]"
                  :key="index_col"
                  style="vertical-align: middle"
                >
                  <div v-if="column.html" v-html="collect(row, column.field)"></div>
                  <div v-else-if="column.icon">
                    <img
                      v-if="collect(row, column.field) != 'follow'"
                      :src="`/assets/images/icons/${collect(row, column.field)}.svg`"
                      width="33"
                      alt="homepage"
                      class="logo-logs"
                    />
                    <img
                      v-else
                      onerror="javascript:this.src='/assets/images/users/avatar_fb.jpg'"
                      :src="`https://graph.facebook.com/${collect(
                        row,
                        column.object_id
                      )}/picture?width=100&height=100`"
                      width="33"
                      :alt="`user+ ${collect(row, column.object_id)}`"
                      class="logo-logs rounded-circle"
                    />
                  </div>
                  <div v-else-if="column.icons">
                    <img
                      v-if="collect(row, column.field) != 'follow'"
                      :src="`/assets/images/icons/${collect(row, column.field)}.svg`"
                      width="40"
                      alt="homepage"
                      class="logo-logs"
                    />
                    <img
                      v-else
                      onerror="javascript:this.src='/assets/images/users/avatar_fb.jpg'"
                      :src="collect(row, column.link)"
                      width="40"
                      :alt="'user' + collect(row, column.object_id)"
                      class="logo-logs rounded-circle"
                    />
                  </div>
                  <div v-else-if="column.index">{{ index + 1 }}</div>
                  <div v-else-if="column.image">
                    <img
                      :src="collect(row, column.field)"
                      :alt="collect(row, column.field)"
                      height="100"
                      class="image-logs"
                    />
                  </div>
                  <div v-else-if="column.fb_link">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`https://www.facebook.com/${collect(row, column.field)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.ig_link_profile">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`https://www.instagram.com/${collect(row, column.field)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.ig_link_full">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`${collect(row, column.field)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.instagram_follow">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`${collect(row, column.field)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.link_link">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`${collect(row, column.link)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.instagram_link">
                    <a
                      target="_blank"
                      :class="['text-primary', { 'cl-red': row.color }]"
                      :href="`${collect(row, column.link)}`"
                      >{{ collect(row, column.field) }}</a
                    >
                  </div>
                  <div v-else-if="column.avatar">
                    <img
                      onerror="javascript:this.src='/assets/images/users/avatar_fb.jpg'"
                      :src="`https://graph.facebook.com/${collect(
                        row,
                        column.field
                      )}/picture?width=40&height=40`"
                      :alt="collect(row, column.field)"
                      width="40"
                      class="logo-logs rounded-circle"
                    />
                  </div>
                  <div v-else-if="column.numeric">
                    {{ collect(row, column.field) | formatNumber }}
                  </div>
                  <div v-else-if="column.timeago">
                    {{ collect(row, column.field) ? timeago(collect(row, column.field)) : "" }}
                  </div>
                  <div v-else-if="column.inputtext">
                    <input
                      type="text"
                      class="form-control input-g"
                      :value="collect(row, column.field)"
                    />
                  </div>
                  <div v-else-if="column.input">
                    <input
                      type="text"
                      class="form-control"
                      @change="changeValue(row, column.field, somethingElse)"
                      v-on:input="somethingElse = $event.target.value"
                      :value="collect(row, column.field)"
                    />
                  </div>
                  <div v-else-if="column.textarea">
                    <textarea
                      class="form-control"
                      rows="3"
                      :value="collect(row, column.field)"
                    ></textarea>
                  </div>
                  <div v-else>{{ collect(row, column.field) }}</div>
                </td>
                <slot name="tbody-tr" :row="row"></slot>
              </tr>
            </tbody>
          </table>
          <div class="table-footer" v-if="paginate">
            <div :class="{ 'datatable-length mr-2': true, rtl: lang.__is_rtl }">
              <label class="mb-0">
                <span>{{ lang["rows_per_page"] }}:</span>
                <select class="browser-default input-gray ml-2" @change="onTableLength">
                  <option
                    v-for="(option, index) in perPageOptions"
                    :value="option"
                    :selected="option == currentPerPage"
                    :key="index"
                  >
                    {{ option === -1 ? lang["all"] : option }}
                  </option>
                </select>
              </label>
            </div>
            <div :class="{ 'datatable-info mr-0': true, rtl: lang.__is_rtl }">
              <span
                >{{
                  (currentPage - 1) * currentPerPage ? (currentPage - 1) * currentPerPage : 1
                }}
                -{{ Math.min(processedRows.length, currentPerPage * currentPage) }}
              </span>
              <span>
                {{ lang["out_of_pages"] }}
              </span>
              <span>
                {{ processedRows.length }}
              </span>
            </div>
            <div>
              <ul class="material-pagination pl-0">
                <li>
                  <a
                    href="javascript:undefined"
                    class="waves-effect btn-flat pr-0"
                    @click.prevent="previousPage"
                    tabindex="0"
                  >
                    <i class="material-icons" style="font-size: 18px">chevron_left</i>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:undefined"
                    class="waves-effect btn-flat pl-0"
                    @click.prevent="nextPage"
                    tabindex="0"
                  >
                    <i class="material-icons" style="font-size: 18px">chevron_right</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="settingTable"
      tabindex="-5"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title bold" id="exampleModalLabel">
              Cài đặt thông tin ưu tiên được hiển thị
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-3" v-for="(column, index) in columns" :key="index">
                <h6 @click="handleActiveColumns(column)" class="font-bold">
                  <i
                    v-if="column.is_show"
                    class="icon-checkbox material-icons font-light mr-1 font-22"
                    >check_box</i
                  >
                  <i v-else class="icon-checkbox material-icons-outlined font-light mr-1 font-22"
                    >check_box_outline_blank</i
                  >
                  {{ column.label }}
                </h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="handleActiveColumns('all')"
              type="button"
              class="btn btn-sm bg-warning text-white float-end"
              data-bs-dismiss="modal"
            >
              Chọn tất cả
            </button>
            <button
              type="button"
              :class="['btn bg-green btn-sm text-white']"
              data-bs-dismiss="modal"
            >
              Đồng Ý
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Fuse from "fuse.js";
import locales from "./locales";
import moment from "moment";

export default {
  props: {
    title: String,
    columns: {},
    rows: {},
    clickable: {
      default: true,
    },
    customButtons: {
      default: () => [],
    },
    perPage: {
      default: () => [10, 20, 30, 40, 50, 500],
    },
    defaultPerPage: {
      default: null,
    },
    sortable: {
      default: true,
    },
    searchable: {
      default: true,
    },
    exactSearch: {
      type: Boolean,
      default: false,
    },
    paginate: {
      default: true,
    },
    exportable: {
      default: true,
    },
    printable: {
      default: true,
    },
    locale: {
      default: "en",
    },
    stt: {
      default: false,
    },
    header: {
      default: true,
    },
  },
  data: () => ({
    currentPage: 1,
    sortColumn: -1,
    sortType: "asc",
    currentPerPage: 10,
    searching: false,
    searchInput: "",
  }),

  computed: {
    user() {
      return this.$store.state.user.user;
    },
    storeActiveColumns() {
      return this.$store.state.datatable.showColumns;
    },
    activeColumns() {
      return this.columns.map((col) => {
        const storeActiveColumn = this.storeActiveColumns.find((x) => x.label === col.label);
        if (storeActiveColumn) {
          col.is_show = storeActiveColumn.is_show;
        } else {
          col.is_show = true;
        }
        return col;
      });
    },
    perPageOptions: function () {
      var options = (Array.isArray(this.perPage) && this.perPage) || [10, 20, 30, 40, 50];
      // Force numbers
      options = options.map((v) => parseInt(v));
      // Set current page to first value
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentPerPage = options[0];
      // Sort options
      options.sort((a, b) => a - b);
      // And add "All"
      options.push(-1);
      // If defaultPerPage is provided and it's a valid option, set as current per page
      if (options.indexOf(this.defaultPerPage) > -1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPerPage = parseInt(this.defaultPerPage);
      }
      return options;
    },
    processedRows: function () {
      var computedRows = this.rows;
      if (this.sortable !== false) {
        computedRows = computedRows.sort((x, y) => {
          if (!this.columns[this.sortColumn]) return 0;
          const cook = (x) => {
            x = this.collect(
              x,
              !this.columns[this.sortColumn].sortBy
                ? this.columns[this.sortColumn].field
                : this.columns[this.sortColumn].sortBy
            );
            if (typeof x === "string") {
              x = x.toLowerCase();
              if (this.columns[this.sortColumn].numeric) {
                x = x.indexOf(".") >= 0 ? parseFloat(x) : parseInt(x);
              }
            }
            return x;
          };
          x = cook(x) ? cook(x) : 0;
          y = cook(y) ? cook(y) : 0;
          return (x < y ? -1 : x > y ? 1 : 0) * (this.sortType === "desc" ? -1 : 1);
        });
      }
      if (this.searching && this.searchInput) {
        const searchConfig = {
          keys: this.columns.map((c) => c.field),
        };
        // Enable searching of numbers (non-string)
        // Temporary fix of https://github.com/krisk/Fuse/issues/144
        searchConfig.getFn = (obj, path) => {
          const property = this.dig(obj, path);
          if (Number.isInteger(property)) return JSON.stringify(property);
          return property;
        };
        if (this.exactSearch) {
          // return only exact matches
          // eslint-disable-next-line no-unused-expressions
          searchConfig.threshold = 0;
          searchConfig.distance = 0;
        }
        computedRows = new Fuse(computedRows, searchConfig).search(this.searchInput);
      }
      return computedRows;
    },
    paginated: function () {
      var paginatedRows = this.processedRows;
      if (this.paginate) {
        paginatedRows = paginatedRows.slice(
          (this.currentPage - 1) * this.currentPerPage,
          this.currentPerPage === -1
            ? paginatedRows.length + 1
            : this.currentPage * this.currentPerPage
        );
      }
      return paginatedRows;
    },
    lang: function () {
      return this.locale in locales ? locales[this.locale] : locales["en"];
    },
  },
  methods: {
    changeValue(row, key, value) {
      this.$emit("changeVaule", row, key, value);
    },
    handleActiveColumns(columnHandle) {
      const storeActiveColumns = this.activeColumns.map((col) => {
        if (columnHandle === "all") {
          col.is_show = true;
        } else {
          if (col.label === columnHandle.label) {
            col.is_show = !col.is_show;
          }
        }
        return col;
      });
      this.$store.commit("SET_DATATABLE_SHOW_COLUMNS", storeActiveColumns);
    },
    nextPage: function () {
      if (this.processedRows.length > this.currentPerPage * this.currentPage) {
        ++this.currentPage;
      }
    },
    previousPage: function () {
      if (this.currentPage > 1) --this.currentPage;
    },
    onTableLength: function (e) {
      this.currentPerPage = parseInt(e.target.value);
    },
    sort: function (index) {
      if (!this.sortable) return;
      if (this.sortColumn === index) {
        this.sortType = this.sortType === "asc" ? "desc" : "asc";
      } else {
        this.sortType = "asc";
        this.sortColumn = index;
      }
    },
    // eslint-disable-next-line no-unused-vars
    search: function (e) {
      this.searching = !this.searching;
    },
    click: function (row) {
      if (!this.clickable) {
        return;
      }
      if (getSelection().toString()) {
        // Return if some text is selected instead of firing the row-click event.
        return;
      }
      this.$emit("row-click", row);
    },
    exportExcel: function () {
      const mimeType = "data:application/vnd.ms-excel";
      const html = this.renderTable().replace(/ /g, "%20");
      const documentPrefix =
        this.title && this.title !== "" ? this.title.replace(/ /g, "-") : "Excel";
      const d = new Date();
      var dummy = document.createElement("a");
      dummy.href = mimeType + ", " + html;
      dummy.download =
        documentPrefix +
        "-" +
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        "-" +
        d.getHours() +
        "-" +
        d.getMinutes() +
        "-" +
        d.getSeconds() +
        ".xls";
      document.body.appendChild(dummy);
      dummy.click();
    },
    exportCsv: function () {
      const mimeType = "data:application/vnd.ms-excel";
      const html = this.renderTableCsv().replace(/ /g, "%20");
      const documentPrefix =
        this.title && this.title !== "" ? this.title.replace(/ /g, "-") : "Sheet";
      const d = new Date();
      var dummy = document.createElement("a");
      dummy.href = mimeType + ", " + html;
      dummy.download =
        documentPrefix +
        "-" +
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        "-" +
        d.getHours() +
        "-" +
        d.getMinutes() +
        "-" +
        d.getSeconds() +
        ".xls";
      document.body.appendChild(dummy);
      dummy.click();
    },
    print: function () {
      let win = window.open("");
      win.document.write(this.renderTable());
      win.print();
      win.close();
    },
    renderTable: function () {
      var table = "<table><thead>";
      table += "<tr>";
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i];
        table += "<th>";
        table += column.label;
        table += "</th>";
      }
      table += "</tr>";
      table += "</thead><tbody>";
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        table += "<tr>";
        for (let j = 0; j < this.columns.length; j++) {
          const column = this.columns[j];
          table += "<td>";
          table += this.collect(row, column.field);
          table += "</td>";
        }
        table += "</tr>";
      }
      table += "</tbody></table>";
      return table;
    },
    renderTableCsv: function () {
      var table = "<table><thead>";
      table += "<tr>";
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i];
        table += "<th>";
        table += column.label;
        table += "</th>";
      }
      table += "</tr>";
      table += "</thead><tbody>";
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        table += "<tr>";
        for (var j = 0; j < this.columns.length; j++) {
          const column = this.columns[j];
          table += "<td>";
          table += this.removeHtml(row, column.field);
          table += "</td>";
        }
        table += "</tr>";
      }
      table += "</tbody></table>";
      return table;
    },
    dig: function (obj, selector) {
      var result = obj;
      const splitter = selector.split(".");
      for (let i = 0; i < splitter.length; i++) {
        if (result === undefined) return undefined;

        result = result[splitter[i]];
      }
      return result;
    },
    collect: function (obj, field) {
      if (typeof field === "function") return field(obj);
      else if (typeof field === "string") return this.dig(obj, field);
      else return undefined;
    },
    removeHtml: function (row, field) {
      if (row && row[field] && typeof row[field] === "string") {
        let input = row[field].match(/input.+?value="(.+?)"/);
        if (input) {
          return input[1];
        }
        let link = row[field].match(/<a.+?>(.+?)</);
        if (link) {
          return link[1];
        }
        let img = row[field].match(/<img.+?src="(.+?)"/);
        if (img) {
          return img[1];
        }
      }
      return row[field];
    },
    timeago: function (value) {
      return moment(value).fromNow();
    },
  },
  mounted: function () {
    // if (!(this.locale in locales)) {
    //     console.error(`vue-materialize-datable: Invalid locale '${this.locale}'`)
    // }
    this.currentPerPage = this.currentPerPage;
  },
};
</script>
<style scoped>
tr.clickable {
  cursor: pointer;
}

#search-input {
  margin: 0;
  border: transparent 0 !important;
  height: 48px;
  color: rgba(0, 0, 0, 0.84);
}

#search-input-container {
  padding: 0 14px 0 24px;
  border-bottom: solid 1px #dddddd;
}

.table-header {
  height: 64px;
  padding-left: 24px;
  padding-right: 14px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  -webkit-display: flex;
  border-bottom: solid 1px #dddddd;
}

.table-header .actions {
  display: -webkit-flex;
  margin-left: auto;
}

.table-header .btn-flat {
  min-width: 36px;
  padding: 0 8px;
}

.table-header input {
  margin: 0;
  height: auto;
}

.table-header i {
  color: rgba(0, 0, 0, 0.54);
}

.table-footer {
  height: 56px;
  padding-left: 24px;
  padding-right: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.54);
}

.table-footer .datatable-length {
  display: -webkit-flex;
  display: flex;
}

.table-footer .datatable-length select {
  outline: none;
}

.table-footer label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  /* works with row or column */

  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.table-footer .select-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  /* works with row or column */

  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.table-footer .datatable-info,
.table-footer .datatable-length {
  margin-right: 32px;
}

.table-footer .material-pagination {
  list-style-type: none;
  display: flex;
  -webkit-display: flex;
  margin: 0;
}

.table-footer .material-pagination li a {
  color: rgba(0, 0, 0, 0.54);
  padding: 0 8px;
  font-size: 24px;
}

.table-footer .select-wrapper input.select-dropdown {
  margin: 0;
  border-bottom: none;
  height: auto;
  line-height: normal;
  font-size: 12px;
  width: 40px;
  text-align: right;
}

.table-footer select {
  width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: auto;
  margin-left: 20px;
}

.table-title {
  font-size: 20px;
}

table td,
table th {
  border-radius: 0;
}

table tr td a {
  color: inherit;
}

table tr td a i {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
}

table th:hover {
  overflow: visible;
  text-overflow: initial;
}

table th.sorting-asc,
table th.sorting-desc {
  color: rgba(0, 0, 0, 0.87);
}

table th.sorting:after,
table th.sorting-asc:after {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  content: "arrow_back";
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  display: none;
  vertical-align: middle;
}

table th.sorting:hover:after,
table th.sorting-asc:after,
table th.sorting-desc:after {
  display: inline-block;
}

table th.sorting-desc:after {
  content: "arrow_forward";
}

table tbody tr:hover {
  background-color: #eee;
}

table th:last-child,
table td:last-child {
  padding-right: 14px;
}

table th:first-child,
table td:first-child {
  padding-left: 24px;
}

.rtl {
  direction: rtl;
}
.settings-table {
  padding: 5px 10px 0px 10px !important;
  border-radius: 10px;
  color: white !important;
}
.icon-settings-table {
  color: white !important;
  vertical-align: bottom;
}
.icon-checkbox {
  vertical-align: bottom;
}
.nowrap {
  white-space: nowrap;
}
.cl-red {
  color: red !important;
}
</style>
